var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      class: [
        {
          active: _vm.active,
          disabled: _vm.disabled,
          closeable: _vm.closeable,
        },
        "floating-tab-item",
        "py-2",
        "px-5",
      ],
      attrs: { vertical: "" },
      on: { click: _vm.click },
    },
    [
      _c(_vm.icon, { tag: "component", staticClass: "floating-tab-icon mb-1" }),
      _c("span", [_vm._v(_vm._s(_vm.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }